// Determine if an element is in the viewport
// https://css-tricks.com/a-few-functional-uses-for-intersection-observer-to-know-when-an-element-is-in-view/

if(!!window.IntersectionObserver){
	let section_livestreaming = document.querySelector("#livestreaming");
	let observer = new IntersectionObserver((entries, observer) => { 
		entries.forEach(entry => {
			if(entry.intersectionRatio==0){
				section_livestreaming.classList.remove("invieport");
				//console.log("Not in the viewport!");
				return
			} else {
				section_livestreaming.classList.add("invieport");
				//console.log("In the viewport!");
				return
			}
		});
	}, { rootMargin: "0px", threshold: 0 });
	observer.observe(section_livestreaming) ;
}

// funzione di lettura e scrittura cookie
function getCookie(name) {
	// Add the = sign
	name = name + "=";

	// Get the decoded cookie
	const decodedCookie = decodeURIComponent(document.cookie);

	// Get all cookies, split on ; sign
	const cookies = decodedCookie.split(";");

	// Loop over the cookies
	for (let i = 0; i < cookies.length; i++) {
		// Define the single cookie, and remove whitespace
		const cookie = cookies[i].trim();

		// If this cookie has the name of what we are searching
		if (cookie.indexOf(name) == 0) {
			// Return everything after the cookies name
			return cookie.substring(name.length, cookie.length);
		}
	}
}

// streaming detect
document.addEventListener('DOMContentLoaded', function(){	
	// menu
	var menu_hook = document.querySelector('.primary-header .wp-block-navigation__responsive-container-content > ul.wp-block-navigation__container');
	if (menu_hook) { 					
		// sposto il blocco
		var menuwatchnow = document.getElementById('menuwatchnow');
		menu_hook.insertAdjacentElement('beforeEnd', menuwatchnow);
	}
	// sezione
	var esports_hook = document.getElementById('esports');
	if (!esports_hook) { var esports_hook = document.querySelector('main'); }		
	if (esports_hook) { 
		// sposto il blocco
		var livestreaming = document.getElementById('livestreaming');
		esports_hook.insertAdjacentElement('afterend', livestreaming);
	}
	
	// creazione offcanvas menu sm
	let buttonCount = document.querySelectorAll('#v-pills-tab .nav-link-button-streaming-tab.d-flex').length;
	//console.log(buttonCount);
	if ( buttonCount > 1 ) {		
		document.getElementById('v-pills-tab').classList.add('offcanvas-lg', 'offcanvas-start', 'offcanvas-bg-streaming');
		document.getElementById('trigger-button-streamer').classList.remove('d-none');
		document.getElementById('trigger-button-streamer').classList.add('d-lg-none');
		document.getElementById('streamer-offcanvas-header').classList.remove('d-none');
		document.getElementById('btn-change-ministream-mobile').classList.remove('d-none');
	} 
	
});




// attivazione prima tabella
// @link: https://bobbyhadz.com/blog/javascript-get-first-element-by-class-name
	
// leggo il cookie selectedstream
let selectedstream = getCookie("selectedstream");
//console.log(selectedstream);
let streamer_pinned = document.getElementById(selectedstream);

if (streamer_pinned) {
	//console.log("selectedstream exist", selectedstream);
	streamer_pinned.classList.add("active");
	let tab_streamer_pinned = document.getElementById("tab-"+selectedstream);
		//console.log("tab_streamer_pinned exist", tab_streamer_pinned);
		tab_streamer_pinned.classList.add("active", "show");
		
} else {
	
	const tab_button = document.getElementsByClassName("nav-link-button-streaming-tab");
	for (const button of tab_button) {
			button.classList.add("active");
			break;
	}
	const tab_content = document.getElementsByClassName("tab-content-pane-streaming-tab");
	for (const content of tab_content) {
			content.classList.add("active", "show");
			break;
	}
}

// leggo il cookie
let ministream = getCookie("ministream");
//console.log(ministream);
if (ministream == "closed") {
	document.querySelector("#livestreaming").classList.add("close-ministream");
}

document.addEventListener("click", function (event) {
	if (!event.target.matches(".btn-close-ministream")) return;
		document.querySelector("#livestreaming").classList.add("close-ministream");
		document.cookie = "ministream=closed; path=/; max-age=${60 * 60 * 24 * 14};";
		//console.log("ministream=closed");
		event.preventDefault();
}, false);
document.addEventListener("click", function (event) {
	if (!event.target.matches(".btn-open-ministream")) return;
		document.querySelector("#livestreaming").classList.remove("close-ministream");
		document.cookie = "ministream=opened; path=/; path=/; max-age=0;";
		//console.log("ministream=opened");
		event.preventDefault();
}, false);



// eventi al click sui bottoni della tabella
const buttonGroup = document.getElementById("v-pills-tab");
const buttonGroupPressed = e => { 
	const isButton = e.target.nodeName === 'BUTTON';
	if(!isButton) {
		return
	}
	
	// if (buttonGroup.classList.contains("show")) {
	// 	buttonGroup.classList.remove("show");
	// 	buttonGroup.removeAttribute("aria-modal");
	// 	buttonGroup.removeAttribute("role");
	// 	document.querySelector(".offcanvas-backdrop").remove();
	// 	document.body.removeAttribute("style");
	// }
	
	// setto il coockie selectedstream per l'active del bottone
	document.cookie = "selectedstream=" + e.target.id + "; max-age=${60 * 60 * 24 * 14};" + " path=/";
	
	//var iframeVideoClickedID = document.getElementById('tab-'+e.target.id);
	//console.log(iframeVideoClickedID);
	
	
	//const iframeContainer = document.querySelectorAll('.tab-content-pane-streaming-tab:not(.active) .iframe-container');
	
// 	iframeContainer.forEach((iframeC) => {		
// 		const iframeVideos = iframeC.querySelectorAll('iframe');
// 		//console.log(iframeC);
// 		//console.log(iframeVideos);
// 		
// 		if (iframeC.classList.contains("container-twitch")) {
// 			const iframeCid = iframeC.id
// 			//const channel = iframeC.getAttribute('data-channel');
// 			
// 			var pl = 'player'+iframeCid;
// 			console.log(pl);
// 			//pl.pause();		
// 			
// 			// iframeCid.addEventListener(Twitch.Embed.VIDEO_READY, () => {
// 			// 	var pl = iframeCid.getPlayer();
// 			// 	pl.pause();
// 			// });
// 
// 		} else {
// 
// 			if (iframeVideos.length > 0) {
// 				iframeVideos.forEach((iframe) => {
// 					if (iframe.contentWindow) {
// 						// Play Youtube Videos
// 						if (iframe.classList.contains("player-youtube")) {
// 							iframe.contentWindow.postMessage(
// 								'{"event":"command","func":"pauseVideo","args":""}',
// 								"*"
// 							);
// 							console.log("YT stopallvideo");
// 						} else {
// 							//console.log("TW stopallvideo");
// 							// var src = iframe.src;
// 							// iframe.src = src;
// 						}
// 						// Pause Vimeo Videos
// 						// if (iframe.src.startsWith("https://player.vimeo.com/")) {
// 						// 	iframe.contentWindow.postMessage('{"method":"pause"}', "*");
// 						// }
// 					}
// 				});
// 				
// 			}
// 		
// 		}
// 	
// 	});
	
	// punto gli iframe all'interno delle tab non attive e aggiorno / metto in pausa il palyer
	const iframeVideos = document.querySelectorAll('.tab-content-pane-streaming-tab:not(.active) iframe');
	//if (iframeVideos.length > 0) {
		iframeVideos.forEach((iframe) => {			
			if (iframe.contentWindow) {
				// Play Youtube Videos
				if (iframe.classList.contains("player-youtube")) {
					//console.log("YT stopallvideo");
					iframe.contentWindow.postMessage(
						'{"event":"command","func":"pauseVideo","args":""}',
						"*"
					);
				} else {
					//console.log("TW stopallvideo");
					iframe.classList.toggle('lazyloaded');
					iframe.classList.add('lazyload');
					var src = iframe.src;
					iframe.src = src;
				}
				// Pause Vimeo Videos
				// if (iframe.src.startsWith("https://player.vimeo.com/")) {
				// 	iframe.contentWindow.postMessage('{"method":"pause"}', "*");
				// }
			}
		});
	//}
	
	const iframeVideosActive = document.querySelector('.tab-content-pane-streaming-tab.active iframe');
	//if (iframeVideosActive.length > 0) {
		if (iframeVideosActive.contentWindow) {
			// Pause Youtube Videos
			if (iframeVideosActive.classList.contains("player-youtube")) {
				//console.log("YT play");
				iframeVideosActive.contentWindow.postMessage(
					'{"event":"command","func":"playVideo","args":""}',
					"*"
				);
			} else {
				//console.log("TW stopallvideo");
				// var src = iframe.src;
				// iframe.src = src;
			}
			// Pause Vimeo Videos
			// if (iframe.src.startsWith("https://player.vimeo.com/")) {
			// 	iframe.contentWindow.postMessage('{"method":"pause"}', "*");
			// }
		}
	//}
	
}
buttonGroup.addEventListener("click", buttonGroupPressed);


/****
TRIGGER MANUALE DI BOOTSTRAP OFFCANVAS
***/

// è necessario per far lavorare bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

document.addEventListener('DOMContentLoaded', function(){	
	const myOffcanvas = document.getElementById('v-pills-tab');
	const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
	
	document.addEventListener("click", function (event) {
		if (!event.target.matches(".trigger-offcanvas-streamer")) return;
			bsOffcanvas.show();
	}, false);
	
	document.addEventListener("click", function (event) {
		if (!event.target.matches(".nav-link-button-streaming-tab")) return;
			bsOffcanvas.hide();
	}, false);
	
});
